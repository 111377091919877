.MuiDataGrid-columnHeaderTitleContainerContent{
	overflow: visible !important;
}


@media print {
	.print {
	   visibility: hidden;
	}
 }
